import { Button } from '@finn/design-system/atoms/button';
import { Facebook } from '@finn/design-system/icons/facebook';
import { Link } from '@finn/design-system/icons/link';
import { SoicialLinkedin } from '@finn/design-system/icons/soicial-linkedin';
import { Twitter } from '@finn/design-system/icons/twitter';
import { useIntl } from '@finn/ui-utils';
import React, { useEffect, useState } from 'react';

import openShareWindow, {
  shareBaseURL,
  SharePlatform,
} from '~/ui-modules/Hero/SocialSharing/openShareWindow';

function copyCurrentLink() {
  const inp = document.createElement('input');
  document.body.appendChild(inp);
  inp.value = `${shareBaseURL}${window.location.pathname}`;
  inp.select();
  document.execCommand('copy', false);
  inp.remove();
}

const SocialSharing = () => {
  const [showCopySuccess, setShowCopySuccess] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    if (showCopySuccess) {
      setTimeout(() => {
        setShowCopySuccess(false);
      }, 2000);
    }
  }, [showCopySuccess, setShowCopySuccess]);

  return (
    <div className="-ml-1 mt-1 scale-90">
      <Button
        size="md"
        variant="ghost"
        className="h-8 w-8 rounded-full p-2"
        onClick={() => openShareWindow(SharePlatform.FACEBOOK)}
      >
        <Facebook />
      </Button>
      <Button
        size="md"
        variant="ghost"
        className="h-8 w-8 rounded-full p-2"
        onClick={() => openShareWindow(SharePlatform.TWITTER)}
      >
        <Twitter className="min-w-5" />
      </Button>
      <Button
        size="md"
        variant="ghost"
        className="h-8 w-8 rounded-full p-2"
        onClick={() => openShareWindow(SharePlatform.LINKEDIN)}
      >
        <SoicialLinkedin className="min-w-5" />
      </Button>
      <Button
        size="md"
        variant="ghost"
        className="h-8 w-8 rounded-full p-2"
        onClick={() => {
          copyCurrentLink();
          setShowCopySuccess(true);
        }}
      >
        <Link className="min-w-5" />
      </Button>
      <div className="relative">
        {showCopySuccess ? (
          <span className="text-snow absolute top-2.5 bg-black px-3">
            {intl.formatMessage('general.guideLinkCopied')}
          </span>
        ) : null}
      </div>
    </div>
  );
};
export default SocialSharing;
