import { useMediaQuery } from '@finn/design-system/helpers/media';
import Head from 'next/head';

import { HeroCosmicMetadata, ResponsiveImageSizes } from '~/types/UIModules';

import HeroContent from './HeroContent';
import { getOptimizedHeroImage } from './utils';

type Props = {
  data: HeroCosmicMetadata;
};

const PrimaryHero = ({ data }: Props) => {
  const { image, ...rest } = data;

  const heroType = rest.level.value;

  const responsiveImage = image && {
    sm: getOptimizedHeroImage(image, ResponsiveImageSizes.SMALL),
    md: getOptimizedHeroImage(image, ResponsiveImageSizes.MEDIUM),
    lg: getOptimizedHeroImage(image, ResponsiveImageSizes.LARGE),
  };

  const isMd = useMediaQuery('md');
  const isSm = useMediaQuery('sm');

  return (
    <div data-testid="hero">
      <Head>
        <link
          rel="preload"
          href={responsiveImage.sm.url}
          as="image"
          media={`(max-width: ${responsiveImage.sm.width}px)`}
        />
        <link
          rel="preload"
          href={responsiveImage.md.url}
          as="image"
          media={`(max-width: ${responsiveImage.md.url}px)`}
        />
        <link
          rel="preload"
          href={responsiveImage.lg.url}
          as="image"
          media={`(max-width: ${responsiveImage.lg.url}px)`}
        />
      </Head>
      <div className="bg-pewter -mb-6 size-full max-w-[2100px] bg-[length:700%_100%] sm:-mb-10">
        <div
          className="min-h-[480px] w-full bg-cover bg-center"
          style={{
            backgroundImage: `url(${responsiveImage[isSm ? 'sm' : isMd ? 'md' : 'lg']?.url})`,
          }}
        >
          <div className="container">
            <HeroContent
              title={rest.title}
              subtitle={rest.subtitle}
              heroType={heroType}
              cta={rest.cta}
              secondaryCta={rest.secondary_cta}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrimaryHero;
