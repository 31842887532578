import { useMediaQuery } from '@finn/design-system/helpers/media';
import Head from 'next/head';
import React from 'react';

import { HeroType, ResponsiveImageSizes } from '~/types/UIModules';

import HeroContent from './HeroContent';
import { getOptimizedHeroImage } from './utils';

const SecondaryHero = ({ data }) => {
  const { image, ...rest } = data;

  const heroType = rest.level.value;
  const isMd = useMediaQuery('md');
  const isSm = useMediaQuery('sm');

  const responsiveImage = image && {
    sm: getOptimizedHeroImage(image, ResponsiveImageSizes.SMALL),
    md: getOptimizedHeroImage(image, ResponsiveImageSizes.MEDIUM),
    lg: getOptimizedHeroImage(image, ResponsiveImageSizes.LARGE),
  };

  const isTertiaryHero = heroType === HeroType.tertiary;

  return (
    <>
      {!isTertiaryHero && responsiveImage && (
        <Head>
          <link
            rel="preload"
            href={responsiveImage.sm?.url}
            as="image"
            media={`(max-width: ${responsiveImage.sm?.width}px)`}
          />
          <link
            rel="preload"
            href={responsiveImage.md?.url}
            as="image"
            media={`(max-width: ${responsiveImage.md?.width}px)`}
          />
          <link
            rel="preload"
            href={responsiveImage.lg?.url}
            as="image"
            media={`(max-width: ${responsiveImage.lg?.width}px)`}
          />
        </Head>
      )}

      <div className="container">
        <HeroContent
          title={rest.title}
          subtitle={rest.subtitle}
          heroType={heroType}
          cta={rest.cta}
          guideMetaData={rest.guide_meta_data?.metadata}
          label={rest.label}
          noImage={isTertiaryHero || !responsiveImage}
        />
      </div>

      {!isTertiaryHero && responsiveImage && (
        <div className="bg-pewter size-full max-w-[2100px] bg-[length:700%_100%]">
          <div
            className="min-h-[196px] w-full bg-cover bg-center sm:min-h-[376px] md:min-h-[768px]"
            style={{
              backgroundImage: `url(${responsiveImage[isSm ? 'sm' : isMd ? 'md' : 'lg']?.url})`,
            }}
          />
        </div>
      )}
    </>
  );
};

export default SecondaryHero;
